import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Layout from "../../components/layout";
import "../../assets/css/main.css";
import Ctahome from "../../components/cta-home";
import WebHero from "../../components/webDevelopment/hero";
import WebService from "../../components/webDevelopment/service";
import Tech from "../../components/tech";
import OtherService from "../../components/other-services";
import Cta from "../../components/cta";

const WebDevelopment = () => {
  const data = useStaticQuery(query);

  return (
    <Layout seo={data.strapiWebdevelopment.seo}>
        <WebHero />
        <Tech />
        <WebService />
        <OtherService />
        <Cta />
    </Layout>
  );
};

const query = graphql`
  query {
    strapiWebdevelopment {
      seo {
        metaTitle
        metaDescription
        shareImage {
          publicURL
        }
      }
    }
  }
`;

export default WebDevelopment;
